import React, { useEffect } from 'react';
import Authenticator from '../../services/Authenticator';
import { handleNavigate } from '../../services/Navigate';

const Logout = () => {    
    useEffect(() => {
        (async() => {
            try {
                await Authenticator.signOut();
                handleNavigate('/');
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    return <></>;
};

export default Logout;